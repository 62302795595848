<template>
  <div class="page-content-inner pt-5 pr-lg-0">
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <div class="page-content-inner pt-lg-0 pr-lg-0">
          <div class="section-header">
            <div class="section-header-left">
              <h3><i class="uil-coffee"></i> {{ $t("home.Wall") }}</h3>
            </div>
          </div>

          <div class="uk-grid-medium" uk-grid>
            <div class="uk-width-expand mr-md-3 mr-lg-0">
              <div uk-sticky="offset:100; bottom:true">
                <div class="card">
                  <div class="card-body text-center">
                    <div class="avatar-parent-child">
                      <img alt="Image placeholder" :src="current_user?.photo_link"
                        class="avatar rounded-circle avatar-lg" />
                      <span class="avatar-child avatar-badge bg-success"></span>
                    </div>
                    <h5 class="h6 mt-4 mb-0">
                      {{ current_user.name + " " + current_user.surname }}
                    </h5>
                    <a href="#" class="d-block text-sm text-muted mb-3">@{{ current_user.name }}.{{ current_user.surname
                      }}</a>

                    <div class="d-flex justify-content-center">
                      <a href="#" @click="$router.push({ name: 'user.profile' })" class="btn btn-primary btn-sm"><i
                          class="uil-user"></i> Profilim</a>
                    </div>
                  </div>
                </div>

                <div class="uk-card-default rounded uk-card-hover mt-3 p-2">
                  <div class="course-card-body">
                    <div class="section-header-left">
                      <h4 class="uk-text-truncate">
                        <i class="uil-comment-alt-edit"></i> {{ $t("general.last_actions") }}
                      </h4>
                    </div>

                    <div class="pt-3 pb-1 text-center">
                      <ul class="uk-list-divider mt-2 pr-3">
                        <li v-for="action in latest_actions">
                          <span v-html="action.message"></span>
                        </li>

                      </ul>
                      <!-- <ul class="uk-list-divider mt-2 pr-3">
                    <li class="">
                      <a
                        class="d-flex justify-content-start align-items-start"
                        href=""
                        ><i class="uil-user-square mr-2"></i>
                        <span class="text-left"
                          >"Sosyallik Garanti'de" adlı bir gönderi
                          paylaştın.</span
                        ></a
                      >
                    </li>
                    <li class="">
                      <a
                        class="d-flex justify-content-start align-items-start"
                        href=""
                        ><i class="uil-user mr-2"></i>
                        <span class="text-left"
                          >Ertan Şen seni bir gönderide etiketledi.</span
                        ></a
                      >
                    </li>
                  </ul> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="uk-width-3-5@l uk-width-5-5 mr-md-3 mr-lg-0">
              <!--- Create Post Section -->
              <div class="card gedf-card" v-if="!userId">
                <div class="card-header">
                  <template v-if="errorMessages">
                    <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger mb-2" uk-alert>
                      <a class="uk-alert-close" uk-close></a>
                      <p style="color: red">
                        <i class="uil-exclamation-triangle"></i> {{ error }}
                      </p>
                    </div>
                  </template>
                  <div v-if="successMessage" class="uk-alert-success mb-3" uk-alert>
                    <a class="uk-alert-close" uk-close></a>
                    <p style="color: green">
                      <i class="uil-exclamation-triangle"></i>
                      {{ successMessage }}
                    </p>
                  </div>
                  <ul class="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                    <!--                  <li class="nav-item">-->
                    <!--                    <a-->
                    <!--                      class="nav-link"-->
                    <!--                      :class="{ active: !form.multi_media }"-->
                    <!--                      @click="form.multi_media = false"-->
                    <!--                      id="posts-tab"-->
                    <!--                      data-toggle="tab"-->
                    <!--                      href="javascript:void(0)"-->
                    <!--                      role="tab"-->
                    <!--                      aria-controls="posts"-->
                    <!--                      aria-selected="true"-->
                    <!--                      >Gönderi Paylaş</a-->
                    <!--                    >-->
                    <!--                  </li>-->
                    <li class="nav-item">
                      <a class="nav-link" id="images-tab" data-toggle="tab" :class="{ active: form.multi_media }"
                        @click="form.multi_media = true" role="tab" aria-controls="images" aria-selected="false"
                        href="javascript:void(0)">{{ $t("home.Share_post") }}</a>
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="posts" role="tabpanel" aria-labelledby="posts-tab">
                      <div class="form-group">
                        <textarea class="form-control" id="message" rows="2" v-model="form.text"
                          placeholder="Ne düşünüyorsun?"></textarea>
                      </div>
                    </div>
                    <div class="tab-pane" id="images" role="tabpanel" :class="{ 'show fade active': form.multi_media }"
                      aria-labelledby="images-tab">
                      <div class="form-group ">
                        <div class="custom-file d-none">
                          <input type="file" class="custom-file-input" id="customFile" @change="updateFiles"
                            ref="myFiles" accept="video/*,image/*" multiple />
                          <label class="custom-file-label" for="customFile">{{
                files.length
                  ? files.length + " files selected"
                  : "Upload image"
              }}</label>
                        </div>

                        <ul class="uk-list-divider mt-4 pl-1 pr-1">
                          <li v-for="(file, index) in files" class="d-flex justify-content-between">
                            <div class="
                              d-flex-inline
                              justify-content-start
                              align-items-start
                            " href="javascript:void(0)">
                              <i class="uil-trash text-danger mr-2" style="cursor: pointer;font-size:18px;"
                                @click="removeFile(index)"></i>

                              <span class="text-left text-secondary">{{ file.name }}</span>

                            </div>
                            <span>
                              <i class="mr-1" :class="file.type.includes('image')
                ? 'uil-image'
                : 'uil-video'
                "></i>
                              {{ bytesToSize(file.size) }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="btn-toolbar justify-content-between">
                    <div class="btn-group">
                      <button type="button" class="btn btn-primary btn-sm" @click="savePost">
                        <i class="uil-arrow-circle-right"></i> {{ $t("general.share") }}
                      </button>

                      <button type="button" class="btn btn-light btn-sm" @click="$refs.myFiles.click()">
                        <i class="uil-images"></i> {{
                files.length
                  ? files.length + " files selected"
                  : "Multimedya Ekle"
              }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Search Section -->
              <div class="
                input-items-group
                d-flex
                justify-content-between
                align-items-center
                mb-3
              ">
                <input class="m-0" type="text" :placeholder="$t('general.search_in_wall')" v-model="filter.search"
                  @keyup="filterSocialPosts" />
              </div>

              <!-- LOADING BOX -->
              <default-loading v-if="posts_loading" :minHeight="'300px'"></default-loading>
              <!--- Post Items -->
              <template>
                <template v-for="(post, index) in formattedPosts">
                  <div v-if="post" class="uk-card uk-card-default rounded mb-4" :key="'post_list_item' + index">
                    <div class="uk-card-header">
                      <div class="uk-grid-small uk-flex-middle" uk-grid>
                        <div class="uk-width-auto">
                          <img class="uk-border-circle" width="40" height="40" :src="post.user?.photo_link" />
                        </div>
                        <div class="uk-width-expand">
                          <h5 class="h6 mt-0 mb-0">
                            {{ post.user?.name + " " + post.user?.surname }}
                          </h5>
                          <p class="uk-margin-remove-top text-black-50 uk-small">
                            @{{
                (
                  post.user?.name +
                  "." +
                  post.user?.surname
                ).toLowerCase()
              }}
                          </p>
                        </div>
                        <div class="uk-width-auto">
                          <div class="text-muted h7 mb-2">
                            <i class="uil-clock"></i>{{ timeFromNow(post.created_at) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="uk-card-body pb-0">
                      <div class="row">
                        <p class="mb-3">
                          {{ post.text }}
                        </p>
                        <p class="mb-3 ml-2" v-if="post.status_id === 1">
                          <strong>(Yönetici Onayına Gönderildi)</strong>
                        </p>
                      </div>
                      <div class="uk-grid-small uk-flex-middle uk-child-width-1-3" uk-grid v-if="post.files.length">
                        <div v-for="file in post.files" :key="'post_file_item_' + post?.id + '_' + file.id">
                          <img v-if="file.file_type == 1" @click="full_screen_image = file.file_link"
                            uk-toggle="target: #modal_full_screen_image" :data-src="file.file_link" :alt="file.name"
                            style="height: 160px; cursor: zoom-in" uk-img />
                          <video v-if="file.file_type == 2" :src="file.file_link" controls
                            style="height: 160px"></video>
                        </div>
                      </div>
                      <ul class="d-flex justify-content-between mt-4 mb-2">
                        <li>
                          <a @click="showLiked(post)" :class="{ 'text-success': post.user_liked_count }"><i
                              class="uil-heart"></i>
                            {{ post.likes_count }} Beğeni</a>
                        </li>
                        <span class="d-flex align-items-center">
                          <li>
                            <a href="javascript:void(0)"><i class="uil-comment"></i>
                              {{ post.comments_count }} Yorum</a>
                          </li>
                          <li class="ml-1" v-if="post.user?.id == current_user?.id">
                            <a href="javascript:;" @click="deletePost(post.id)">
                              | <i class="uil-trash-alt"></i> Sil
                            </a>
                          </li>
                        </span>
                      </ul>
                    </div>
                    <div class="uk-card-footer">
                      <a href="#" @click.prevent="likePost(post.id, index)" class="card-link">
                        <span v-if="!post.user_liked_count">
                          <i class="uil-thumbs-up"></i> Beğen</span>
                        <span v-else><i class="uil-thumbs-down"></i> Beğenme</span>
                      </a>
                      <a href="javascript:void(0)" class="card-link" @click="openCommentArea"><i
                          class="uil-comment"></i>
                        Yorum Yap</a>
                      <!-- <a href="#" class="card-link"
                    ><i class="uil-star"></i> Öner</a
                  > -->
                      <div class="form-group comment_section text-right" style="display: none">
                        <textarea class="form-control mt-4 text-left" rows="2" v-model="form.comment"
                          @blur="setDisplayNone" :placeholder="$t('general.what_are_you_thinking')"></textarea>
                        <button type="button" v-if="form.comment.length" @click="commentPost(post.id, index)"
                          class="btn btn-sm btn-success">
                          {{ $t("general.save") }}
                        </button>
                      </div>
                      <template v-if="post.comments.length">
                        <hr />
                        <ul class="uk-comment-list comment_tree_view parent_comment_tree_view">
                          <li v-for="(comment, comment_index) in post.comments"
                            :key="'comment_item_' + comment.id + '_' + comment_index">
                            <CommentItem @reset="filterSocialPosts" :current_user="current_user" :post_id="post.id"
                              :post_index="index" @reply="replyComment" :comment="comment"></CommentItem>
                            <hr />
                            <div v-for="(comment, comment_index) in comment.approved_replies"
                              :key="'comment_item_' + comment.id + '_' + comment_index">
                              <CommentItem @reset="filterSocialPosts" :current_user="current_user" :post_id="post.id"
                                :post_index="index" @reply="replyComment" :comment="comment"></CommentItem>
                            </div>
                          </li>
                        </ul>

                      </template>

                    </div>
                  </div>
                </template>

                <template v-if="!formattedPosts.length">

                  <div class="row text-center">
                    <div class="col-12">

                      <div class="uk-alert-success bg-success-700 h-25px" uk-alert>
                        <p style="color: green">
                          Henüz bir paylaşımı bulunmamaktadır
                        </p>
                      </div>
                    </div>
                  </div>

                </template>
                <!-- Modal -->
                <div id="modal_full_screen_image" class="uk-modal-full uk-modal p-5" uk-modal
                  style="z-index: 999999; background-color: rgba(0, 0, 0, 0.5)">
                  <div class="uk-modal-dialog h-100 uk-background-cover text-right"
                    :style="{ backgroundImage: 'url(' + full_screen_image + ')' }">
                    <button type="button" style="
                    margin-top: -33px;
                    margin-right: -25px;
                    background: rgba(0, 0, 0, 0.5);
                    color: white;
                  " class="uk-icon-button uk-modal-close" uk-close></button>
                  </div>
                </div>
                <!-- Pagination -->
                <pagination v-if="pagination && pagination.hasOwnProperty('links')" :pagination="pagination"
                  @changePage="setPage($event)"></pagination>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="modal_likedList" class="uk-modal" uk-modal="">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title"><i class="uil-heart"></i>Beğenen Kişiler</h2>
          <ol>
            <li v-for="likedName in likedList">{{ likedName.fullname }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/core/services";
import moment from "moment";
import module, {
  BASE_URL,
  MODULE_NAME,
  ERROR,
  ITEMS,
  GET_ITEMS,
  SET_ITEMS,
  CREATE_ITEM,
  ITEM,
  UPDATE_ITEM,
  LOADING,
} from "@/core/services/store/social_post.module";
import user_module, {
  MODULE_NAME as USER_MODULE_NAME,
  GET_USER,
  CURRENT_ITEM as CURRENT_USER,
} from "@/core/services/store/user.module";
import { CREATE_ITEM as REST_CREATE_ITEM, GET_ITEMS as REST_GET_ITEMS, DELETE_ITEM_BY_ID } from "@/core/services/store/REST.module";
import Pagination from "@/assets/components/Pagination";
import CommentItem from "@/view/components/wall/Comment";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import axios from "axios";
export default {
  name: "Wall",
  components: {
    Pagination,
    CommentItem,
    DefaultLoading
  },
  props: {
    id: {
      required: false,
      default: null
    },
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
    registerStoreModule(USER_MODULE_NAME, user_module);
  },
  data() {
    return {
      likedList: [],
      pagination: null,
      successMessage: null,
      files: [],
      userId: null,
      errorMessages: [],
      userSelfURL: "api/user/self",
      userShowURL: "api/user/",
      likeURL: "api/social-post/like/",
      commentURL: "api/social-post/comment/",
      full_screen_image: null,
      form: {
        multi_media: true,
        text: "",
        comment: "",
      },
      filter: {
        page: 1,
        per_page: 10,
        search: null,
        order: "desc",
        sort: "id",
        id: null,
      },
      latest_actions: [],
    };
  },
  computed: {
    posts: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    posts_loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    formattedPosts() {
      let items = this.posts;
      if (items === null || !("data" in items) || !items.data.length) return [];
      this.pagination = items;
      return items.data;
    },
    post: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEM];
      },
      set(value) { },
    },
    current_user: {
      get() {
        let item = this.$store.getters[USER_MODULE_NAME + "/" + CURRENT_USER];

        if (item && this.userId) return item.item;

        return item;
      },
      set(val) { },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
  },
  methods: {
    showLiked(event) {
      axios.get(`/api/social-post/like/` + event.id + `/show
       `, {
        headers: this.apiHeaders,
      })
        .then((response) => {
          this.likedList = response.data
          UIkit.modal("#modal_likedList").show();
        });
    },
    deletePost(id) {
      let self = this;
      self.$store
        .dispatch(DELETE_ITEM_BY_ID, {
          url: '/api/social-post/' + id,
          acceptPromise: true,
        })
        .then((result) => {
          self.filterSocialPosts()
        });
    },
    getLatestActions() {
      let url = this.userId ? 'api/social-post/latest-actions/' + this.userId : 'api/social-post/latest-actions';
      this.$store.dispatch(REST_GET_ITEMS, {
        url: url,
        acceptPromise: true,
      }).then(response => {
        if (typeof response.data !== undefined) {
          response.data.forEach(item => {
            let message = '';
            let label = (item.text.trim() !== null) ? item.text + ' adlı' : 'Bir';

            switch (item.data_type) {
              case 'likes':
                message = '<i class="uil-thumbs-up"></i> ' + label + ' gönderiyi beğendin';
                break;
              case "social_posts":
                message = '<i class="uil-share-alt"></i> ' + label + ' gönderiyi paylaştın';
                break;
              case "comments":
                message = '<i class="uil-comment-alt"></i> ' + label + ' gönderiye yorum yaptın';
                break;
            }

            item.message = message;
            this.latest_actions.push(item);
          });

          //this.latest_actions = response.data;
        }
      })
    },
    getCurrentUser() {
      let url = this.userId ? this.userShowURL + this.userId : this.userSelfURL;
      this.$store.dispatch(USER_MODULE_NAME + "/" + GET_USER, {
        url: url,
        acceptPromise: true,
      });
    },
    filterSocialPosts(id = null) {
      let self = this;
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          like: self.filter.search,
          page: self.filter.page,
          per_page: self.filter.per_page,
          sort: self.filter.sort,
          order: self.filter.order,
          id: id,
          user_id: this.userId
        },
      });
    },
    savePost() {
      let self = this;
      let form = self.form;
      let formData = new FormData();
      this.errorMessages = [];
      if (form.text.length < 3) {
        this.errorMessages.push(
          this.$t("validation.required_field").replace(
            ":NAME",
            this.$t("post.text")
          )
        );
        return false;
      }
      formData.append("text", form.text);
      self.files.forEach((file) => {
        formData.append("files[]", file);
      });

      store
        .dispatch(MODULE_NAME + "/" + CREATE_ITEM, {
          url: BASE_URL,
          contents: formData,
        })
        .then(() => {
          if (!self.error) {
            // self.successMessage = self.$t("general.successfully_created");
            form.text = "";
            self.files = [];
            self.posts.data.unshift(self.post);
          } else self.errorMessages.push(self.error);
        });
    },
    likePost(id, index) {
      let self = this;
      this.$store
        .dispatch(REST_CREATE_ITEM, {
          url: this.likeURL + id,
          acceptPromise: true,
        })
        .then((result) => {
          self.posts.data[index].likes = result.data;
          self.posts.data[index].likes_count = result.data.length;
          self.posts.data[index].user_liked_count = self.posts.data[index]
            .user_liked_count
            ? 0
            : 1;
        });
    },
    commentPost(id, index) {
      let self = this;
      let formData = new FormData();
      formData.append("text", self.form.comment);
      this.$store
        .dispatch(REST_CREATE_ITEM, {
          url: this.commentURL + id,
          contents: formData,
          acceptPromise: true,
        })
        .then((result) => {
          if (!self.error) {
            self.posts.data[index].comments_count += 1
            self.posts.data[index].comments.unshift(result.data);
          } else self.errorMessages.push(self.error);
        });
    },
    sharePost(id) { },
    openCommentArea(eve) {
      let sibling = eve.target.nextElementSibling;
      sibling.style.display = "block";
      sibling.querySelector("textarea").focus();
    },
    setDisplayNone(eve) {
      setTimeout(() => {
        this.form.comment = "";
        eve.target.parentElement.style.display = "none";
      }, 200);
    },
    replyComment(data) {
      let self = this;
      let formData = new FormData();
      formData.append("text", data.text);
      formData.append("parent_id", data.comment_id);
      this.$store
        .dispatch(REST_CREATE_ITEM, {
          url: this.commentURL + data.id + "/reply/" + data.comment_id,
          contents: formData,
          acceptPromise: true,
        })
        .then((result) => {
          if (!self.error) {
            self.posts.data[data.index].comments_count += 1
            self.posts.data[data.index].comments = result.data;
          } else self.errorMessages.push(self.error);
        });
    },
    updateFiles(eve) {
      let files = eve.target.files;
      if (!files) return;
      this.files.push(...files);
    },
    removeFile(index) {
      let fileListArr = Array.from(this.files);
      fileListArr.splice(index, 1);
      if (fileListArr.length > 0) this.files = fileListArr;
      else this.files = [];
    },
    bytesToSize(bytes) {
      let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    resetMessages() {
      this.errorMessages = [];
      this.successMessage = null;
    },
    timeFromNow(date) {
      return moment(date).fromNow();
    },
    setPage(page) {
      let self = this;
      self.filter.page = page;
      self.filterSocialPosts();
    },
  },
  mounted() {
    if (this.$route.params.search_text) {
      this.filter.search = this.$route.params.search_text
      this.filterSocialPosts();
    }
    if (!this.socialPostVisible) {
      this.$router.push({ name: 'user.index' })
    }
    this.userId = this.$route.params.user;
    this.getCurrentUser();
    this.filterSocialPosts(this.id);
    this.getLatestActions();
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal_full_screen_image");
    if (modal) modal.$destroy(true);
  },
  watch: {
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>

<style scoped>
input.custom-file-input:focus {
  opacity: 0;
}

ul,
ol {
  list-style-type: unset;
  padding-left: inherit !important;
}
</style>
